import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { Button } from '../Button';
import { EMAIL_REGEX } from '../../constants';
import './styles.scss';

const encode = data => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
};

const defaultDevice = {
    value: '',
    label: 'Select',
};

const defaultAge = {
    value: '',
    label: 'Select',
};
const defaultUsage = {
    value: '',
    label: 'Select',
};
const defaultFrequency = {
    value: '',
    label: 'Select',
};
const defaultStudy = {
    value: '',
    label: 'Select',
};

const deviceOptions = [
    { value: 'Android', label: 'Android' },
    { value: 'iPhone', label: 'iPhone' },
];

const ageOptions = [
    { value: '15-19', label: '15-19' },
    { value: '20-24', label: '20-24' },
    { value: '25-29', label: '25-29' },
    { value: '30-39', label: '30-39' },
    { value: '40-49', label: '40-49' },
    { value: '50-59', label: '50-59' },
    { value: '60+', label: '60+' },
];

const usageOptions = [
    { value: "I didn't download it", label: "I didn't download it" },
    { value: 'I did not use it', label: 'I did not use it' },
    { value: 'Less than once a month', label: 'Less than once a month' },
    { value: 'Once or twice a month', label: 'Once or twice a month' },
    { value: 'Weekly', label: 'Weekly' },
    { value: 'Daily', label: 'Daily' },
];

const frequencyOptions = [
    { value: 'Weekly', label: 'Weekly' },
    { value: 'Monthly', label: 'Monthly' },
    { value: 'Few times a year', label: 'Few times a year' },
];

const studyOptions = [
    { value: 'Interview', label: 'Interview (speaking)' },
    { value: 'Online Test', label: 'Online Test (reading/writing)' },
    { value: 'Both', label: 'Both' },
];

const TesterContactForm = props => {
    const [state, setState] = useState({});
    const [selectedDevice, setSelectedDevice] = useState(defaultDevice);
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [selectedAge, setSelectedAge] = useState(defaultAge);
    const [selectedUsage, setSelectedUsage] = useState(defaultUsage);
    const [selectedFrequency, setSelectedFrequency] = useState(defaultFrequency);
    const [selectedStudy, setSelectedStudy] = useState(defaultStudy);

    const handleChange = e => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const isFormValid = () => {
        if (!state.email) {
            setErrorMessage('Please provide your email address.');
            return false;
        }
        if (!EMAIL_REGEX.test(state.email)) {
            setErrorMessage('Please provide a valid email address.');
            return false;
        }
        if (!state.name) {
            setErrorMessage('Please provide your name.');
            return false;
        }
        if (!state.country) {
            setErrorMessage('Please provide the country name.');
            return false;
        }
        if (selectedDevice.value === '') {
            setErrorMessage('Please select device.');
            return false;
        }
        if (selectedAge.value === '') {
            setErrorMessage('Please select age group.');
            return false;
        }
        if (selectedUsage.value === '') {
            setErrorMessage('Please select usage.');
            return false;
        }
        if (selectedFrequency.value === '') {
            setErrorMessage('Please select frequency.');
            return false;
        }
        if (selectedStudy.value === '') {
            setErrorMessage('Please select study type.');
            return false;
        }

        return true;
    };

    const handleSubmit = e => {
        e.preventDefault();
        setErrorMessage(null);
        setSuccessMessage(null);

        if (!isFormValid()) {
            return;
        }

        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': props.formName,
                ...state,
                device: selectedDevice.value,
                age: selectedAge.value,
                usage: selectedUsage.value,
                frequency: selectedFrequency.value,
                study: selectedStudy.value,
            }),
        })
            .then(() => {
                setState({});
                setSelectedDevice(defaultDevice);
                setSelectedAge(defaultAge);
                setSelectedUsage(defaultUsage);
                setSelectedFrequency(defaultFrequency);
                setSelectedStudy(defaultStudy);
                setSuccessMessage(
                    'Thank you for getting in touch! We will reach out when we conduct our next study.'
                );
            })
            .catch(() => {
                setErrorMessage('There was a problem with submission. Please try again');
            });
    };

    return (
        <div className="tester-signup-form">
            <h3>Sign up!</h3>
            <form
                name={props.formName}
                method="post"
                onSubmit={handleSubmit}
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                noValidate
            >
                <input type="hidden" name="form-name" value={props.formName} />
                <div hidden>
                    <label>
                        Don’t fill this out: <input name="bot-field" onChange={handleChange} />
                    </label>
                </div>
                <div>
                    <label className="input-label">
                        Your name
                        <br />
                        <input
                            className="input-field"
                            type="name"
                            name="name"
                            value={state.name ?? ''}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label className="input-label">
                        Your email
                        <br />
                        <input
                            className="input-field"
                            type="email"
                            name="email"
                            value={state.email ?? ''}
                            onChange={handleChange}
                            required
                        />
                    </label>
                </div>
                <div>
                    <label className="input-label">
                        Your country
                        <br />
                        <input
                            className="input-field"
                            type="text"
                            name="country"
                            value={state.country ?? ''}
                            onChange={handleChange}
                            required
                        />
                    </label>
                </div>
                <div>
                    <label className="input-label">
                        Your mobile device
                        <br />
                        <Select
                            className="input-field"
                            name="device"
                            value={selectedDevice}
                            options={deviceOptions}
                            onChange={setSelectedDevice}
                        />
                    </label>
                </div>
                <div>
                    <label className="input-label">
                        Your age group
                        <br />
                        <Select
                            className="input-field"
                            name="age"
                            value={selectedAge}
                            options={ageOptions}
                            onChange={setSelectedAge}
                        />
                    </label>
                </div>
                <div>
                    <label className="input-label">
                        How often did you use the app in the last 3 months?
                        <br />
                        <Select
                            className="input-field"
                            name="usage"
                            value={selectedUsage}
                            options={usageOptions}
                            onChange={setSelectedUsage}
                        />
                    </label>
                </div>
                <div>
                    <label className="input-label">
                        How frequently can you participate in studies?
                        <br />
                        (can vary from 10 minutes per online tests up to 1 hour for interviews)
                        <br />
                        <Select
                            className="input-field"
                            name="frequency"
                            value={selectedFrequency}
                            options={frequencyOptions}
                            onChange={setSelectedFrequency}
                        />
                    </label>
                </div>
                <div>
                    <label className="input-label">
                        What kind of studies are you comfortable taking part in English?
                        <br />
                        <Select
                            className="input-field"
                            name="study"
                            value={selectedStudy}
                            options={studyOptions}
                            onChange={setSelectedStudy}
                        />
                    </label>
                </div>
                <div>
                    {successMessage ? <div id="success-response">{successMessage}</div> : null}
                    {errorMessage ? <div id="error-response">{errorMessage}</div> : null}
                </div>

                <div style={{ textAlign: 'center' }}>
                    <Button type="submit" buttonStyle="btn--primary--outline">
                        Send{' '}
                    </Button>
                </div>
            </form>
        </div>
    );
};

TesterContactForm.propTypes = {
    formName: PropTypes.string.isRequired,
};

export default TesterContactForm;

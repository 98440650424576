import React from 'react';
import '../styles/button.scss';

const styles = [
    'btn--primary--solid',
    'btn--primary--outline',

    'btn--danger--solid',
    'btn--danger--outline',

    'btn--warning--solid',
    'btn--warning--outline',

    'btn--success--solid',
    'btn--success--outline',
];

export const Button = ({ children, type, onClick, buttonStyle, ...rest }) => {
    const btnStyle = styles.includes(buttonStyle) ? buttonStyle : '';

    return (
        <button className={`btn ${btnStyle} ${rest.className}`} onClick={onClick} type={type}>
            {children}
        </button>
    );
};

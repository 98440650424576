import React, { useEffect, useState } from 'react';

import '../styles/global.scss';
import '../styles/app.scss';

import { isMobileOrTablet } from '../utils/app-utils';

import AndroidImage from '../images/Android-Download-300x89.png';
import IphoneImage from '../images/app-store-download.png';
import { APPLE_STORE_LINK, GOOGLE_PLAY_STORE_LINK } from '../constants';

export default function DownloadAppButtons() {
    const [userAgent, setUserAgent] = useState('');

    useEffect(() => {
        setUserAgent(navigator.userAgent);
    }, []);

    return (
        <>
            {/* update anchor tag to Gatsby Link on version update; current version does not support target tag */}
            <a
                target={`${isMobileOrTablet(userAgent) ? '' : '_blank'}`}
                aria-label="Download app from Google Play Store"
                href={`${isMobileOrTablet(userAgent) ? GOOGLE_PLAY_STORE_LINK : '/app/android'}`}
            >
                <img src={AndroidImage} alt="Download app from Google Play Store" />
            </a>
            <a
                target={`${isMobileOrTablet(userAgent) ? '' : '_blank'}`}
                aria-label="Download app from App Store"
                href={`${isMobileOrTablet(userAgent) ? APPLE_STORE_LINK : '/app/ios'}`}
            >
                <img src={IphoneImage} alt="Download app from App Store" />
            </a>
        </>
    );
}

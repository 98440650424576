import React from 'react';
import { motion } from 'framer-motion';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import TesterContactForm from '../components/TesterContactForm/TesterContactForm';
import SEO from '../components/seo';
import Title from '../components/Title';
import Subtitle from '../components/SubTitle';
import DownloadAppButtons from '../components/DownloadAppButtons';

import '../styles/global.scss';
import '../styles/app.scss';

import PrayHands from '../images/folded-hands.png';

const App = () => {
    return (
        <Layout>
            <SEO title="App" />
            <section className="app section">
                <div className="container pageHero">
                    <div className="textBlock">
                        <Title>
                            Join the movement. <br /> Download Earth Hero now.
                        </Title>
                        <Subtitle>
                            Join with others to collectively address the climate crisis.
                        </Subtitle>
                        <motion.div
                            initial={{
                                opacity: 0,
                                scale: 0.8,
                                y: 20,
                            }}
                            animate={{
                                scale: 1,
                                opacity: 1,
                                y: 0,
                            }}
                            transition={{
                                duration: 1,
                                delay: 0.4,
                            }}
                            className="app__downloads"
                        >
                            <DownloadAppButtons />
                        </motion.div>
                    </div>
                </div>
            </section>

            <section className="app section app__experience">
                <h2 className="app__experience--headline">How the Earth Hero app can help:</h2>
                <ul>
                    <li>Fill out a short survey to determine your carbon emissions.</li>
                    <li>
                        Set pollution reduction targets based on science-based IPCC recommendations.
                    </li>
                    <li>Discover action ideas and track progress with helpful tools.</li>
                    <li>Learn about social actions with a multiplier effect to change systems.</li>
                    <li>Get personalized suggestions for your situation.</li>
                    <li>Take action to improve lives and care for our planet.</li>
                </ul>
            </section>

            <div className="app__help">
                <img src={PrayHands} alt="praying hands" />
                <h2 className="app__help--headline">We need your help!</h2>
                <p className="app__help--description">
                    We are early on in the Earth Hero journey, so we would love help with testing
                    our upcoming app releases.
                </p>
                <TesterContactForm formName="testerSignup" />
            </div>
        </Layout>
    );
};

export default App;

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

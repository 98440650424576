import React from 'react';
import { motion } from 'framer-motion';

import '../styles/global.scss';

const SubTitle = ({ children }) => (
    <motion.h2
        initial={{
            opacity: 0,
            scale: 0.8,
            y: 20,
        }}
        animate={{
            scale: 1,
            opacity: 1,
            y: 0,
        }}
        transition={{
            duration: 1,
            delay: 0.2,
        }}
        className="pageHeroSubhead"
    >
        {children}
    </motion.h2>
);

export default SubTitle;
